import { __awaiter, __extends, __generator, __read, __spreadArray } from "tslib";
import { Logger } from '../../../logger';
import { STATUS } from '../../../constants';
import BaseExtensionStrategy from './base-ext';
var MAX_POLLING_ERRORS = 3;
var SafariAppStrategy = /** @class */ (function (_super) {
    __extends(SafariAppStrategy, _super);
    function SafariAppStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pollingRequestErrors = 0;
        _this.extensionDetected = false;
        _this.name = 'safari';
        /**
         * Resolves the http response
         */
        _this.resolveHttpResponse = function (evt) {
            if (evt.detail) {
                Logger.trace('Safari extension impl received response: ' + JSON.stringify(evt));
                var id = evt.detail.request_id;
                /**
                 * Each instance of this class will receive document events, but
                 * the request might not have originated from this instance.
                 */
                if (!(id in _this.outstandingRequests)) {
                    return;
                }
                var path = _this.outstandingRequests[id].req.uri_reference;
                var resolve = _this.outstandingRequests[id].resolve;
                delete _this.outstandingRequests[id];
                if (evt.detail.status === 0 && path.indexOf('/connect/transfers/activity') > 0
                    && _this.pollingRequestErrors < MAX_POLLING_ERRORS) {
                    _this.pollingRequestErrors++;
                    return;
                }
                else {
                    _this.pollingRequestErrors = 0;
                    resolve({
                        status: evt.detail.status,
                        body: evt.detail.body,
                        requestId: id
                    });
                }
            }
        };
        _this.checkEvent = function () {
            document.dispatchEvent(new CustomEvent('AsperaConnectCheck', {}));
        };
        _this.detectExtension = function (timeoutMs) {
            if (timeoutMs === void 0) { timeoutMs = -1; }
            return __awaiter(_this, void 0, void 0, function () {
                var timeoutPromise, waitUntilDetected, found;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            /** First check if we have already detected the extension */
                            if (this.extensionDetected) {
                                Logger.debug('Skipping extension check - already detected.');
                                return [2 /*return*/, true];
                            }
                            timeoutPromise = new Promise(function (resolve) {
                                setTimeout(resolve, timeoutMs, false);
                            });
                            waitUntilDetected = function () {
                                return new Promise(function (resolve) {
                                    var attemptNumber = 1;
                                    var check = function () {
                                        Logger.debug('Detecting Connect extension. Attempt ' + attemptNumber);
                                        attemptNumber++;
                                        // Event based
                                        _this.checkEvent();
                                        // DOM based extension detector
                                        var connectDetected = document.getElementById('aspera-connect-detector');
                                        if (connectDetected) {
                                            var extensionEnable = connectDetected.getAttribute('extension-enable');
                                            if (extensionEnable === 'true') {
                                                Logger.debug('Detected extension');
                                                clearInterval(_this.detectionRetry);
                                                // Additional check to see if connect check is responding
                                                _this.checkEvent();
                                                // wait for connect check response for 1 second
                                                setTimeout(function () {
                                                    if (!_this.extensionDetected) {
                                                        window.postMessage('show_safari_mitigate', '*');
                                                        resolve(false);
                                                    }
                                                    else {
                                                        /** Go to running here if Connect was installed during loop after initial timeout */
                                                        _this.changeConnectStatus(STATUS.RUNNING);
                                                        resolve(true);
                                                    }
                                                }, 1000);
                                            }
                                        }
                                        // create detector
                                        if (!connectDetected) {
                                            Logger.debug('Creating detector in sdk...');
                                            var div = document.createElement('div');
                                            div.id = 'aspera-connect-detector';
                                            div.setAttribute('extension-enable', 'false');
                                            document.body.appendChild(div);
                                        }
                                    };
                                    // NOTE: Safari bugs sometime leads to breakdown in getting responses
                                    var versionResponse = function (evt) {
                                        if (evt.type === 'AsperaConnectCheckResponse' && 'detail' in evt && typeof evt.detail === 'object') {
                                            document.removeEventListener('AsperaConnectCheckResponse', versionResponse);
                                            Logger.debug('Got response from Connect: ' + JSON.stringify(evt.detail));
                                            clearInterval(_this.detectionRetry);
                                            _this.extensionDetected = true;
                                            resolve(true);
                                        }
                                    };
                                    document.addEventListener('AsperaConnectCheckResponse', versionResponse);
                                    var interval = timeoutMs === -1 ? 500 : 200;
                                    _this.detectionRetry = setInterval(check, interval);
                                    check();
                                });
                            };
                            return [4 /*yield*/, Promise.race(__spreadArray(__spreadArray([], __read(timeoutMs !== -1 ? [timeoutPromise] : []), false), [
                                    waitUntilDetected()
                                ], false))];
                        case 1:
                            found = _a.sent();
                            clearInterval(this.detectionRetry);
                            return [2 /*return*/, found];
                    }
                });
            });
        };
        _this.stop = function () {
            clearTimeout(_this.detectionRetry);
        };
        _this.startup = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Logger.debug('startup()');
                        // @ts-ignore
                        document.addEventListener('AsperaConnectResponse', this.resolveHttpResponse);
                        /** Await extension detection */
                        return [4 /*yield*/, this.detectExtension()];
                    case 1:
                        /** Await extension detection */
                        _a.sent();
                        Logger.debug('safari init finished');
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    SafariAppStrategy.prototype.triggerExtensionCheck = function () {
        var hiddenIframe = document.createElement('IFRAME');
        hiddenIframe.src = 'fasp://initialize?checkextensions';
        hiddenIframe.style.visibility = 'hidden';
        hiddenIframe.style.position = 'absolute';
        hiddenIframe.style.width = '0px';
        hiddenIframe.style.height = '0px';
        hiddenIframe.style.border = '0px';
        document.body.appendChild(hiddenIframe);
    };
    return SafariAppStrategy;
}(BaseExtensionStrategy));
export default SafariAppStrategy;
