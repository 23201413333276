import { __read, __spreadArray } from "tslib";
import { copyObject, isNullOrUndefinedOrEmpty } from '../utils';
import { validateMethod, validateName } from './validators';
var Request = /** @class */ (function () {
    function Request() {
        this.validators = [];
    }
    Request.prototype.addSettings = function (addStandardSettings) {
        var data = copyObject(this.body);
        data = addStandardSettings(data);
        /** Add additional settings for transfer requests */
        if (this.name === 'startTransfer' && this.requestId) {
            var transferSpec = void 0;
            for (var i = 0; i < data.transfer_specs.length; i++) {
                transferSpec = data.transfer_specs[i];
                transferSpec = addStandardSettings(transferSpec);
                transferSpec.aspera_connect_settings.request_id = this.requestId;
                if (isNullOrUndefinedOrEmpty(transferSpec.aspera_connect_settings.back_link)) {
                    transferSpec.aspera_connect_settings.back_link = window.location.href;
                }
            }
        }
        this.body = data;
    };
    Request.prototype.setBody = function (body) {
        this.body = body;
        return this;
    };
    Request.prototype.setMethod = function (method) {
        this.method = method;
        return this;
    };
    Request.prototype.setName = function (name) {
        this.name = name;
        return this;
    };
    Request.prototype.setParam = function (param) {
        this.param = param;
        return this;
    };
    Request.prototype.setRequestId = function (id) {
        this.requestId = id;
        return this;
    };
    Request.prototype.setValidator = function () {
        var validators = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            validators[_i] = arguments[_i];
        }
        this.validators = __spreadArray(__spreadArray([], __read(this.validators), false), __read(validators), false);
        return this;
    };
    Request.prototype.validate = function () {
        var _this = this;
        /** Add default validators here */
        this.validators.push(validateName, validateMethod);
        this.validators.forEach(function (validator) {
            validator(_this);
        });
    };
    Request.prototype.send = function (api) {
        /** Run all validators */
        this.validate();
        return api.send(this);
    };
    return Request;
}());
export default Request;
