import { isNullOrUndefinedOrEmpty } from '../utils';
export var validateAuthSpec = function (request) {
    validateOptions(request);
    var required = ['remote_host'];
    validateKeys(request, 'authSpec', required);
};
export var validateBufferOptions = function (request) {
    validateOptions(request);
    var required = ['path', 'offset', 'chunkSize'];
    validateKeys(request, '#readChunkAsArrayBuffer options', required);
};
export var validateChecksumOptions = function (request) {
    validateOptions(request);
    var required = ['path'];
    validateKeys(request, '#getChecksum options', required);
    var allowedMethods = ['md5', 'sha1', 'sha256', 'sha512'];
    var body = request.body;
    if (body && body.checksumMethod && allowedMethods.indexOf(body.checksumMethod) === -1) {
        throwError("".concat(body.checksumMethod, " is not a supported checksum method"));
    }
};
export var validateMethod = function (request) {
    var method = request.method;
    if (!method) {
        throw new Error('Request is missing property: method');
    }
};
export var validateName = function (request) {
    var name = request.name;
    if (!name) {
        throw new Error('Request is missing property: name');
    }
};
export var validateArrayBufferOptions = function (request) {
    validateOptions(request);
    var required = ['path'];
    validateKeys(request, '#readAsArrayBuffer options', required);
};
export var validateTransferId = function (request) {
    var param = request.param;
    if (isNullOrUndefinedOrEmpty(param)) {
        throwError('Must provide transfer id.');
    }
};
var validateBody = function (request, msg) {
    var body = request.body;
    if (isNullOrUndefinedOrEmpty(body)) {
        throwError(msg);
    }
};
/*
 * Validate request body contains given keys
 */
var validateKeys = function (request, parameterName, keys) {
    keys.forEach(function (key) {
        if (isNullOrUndefinedOrEmpty(request.body[key])) {
            var msg = "Invalid ".concat(parameterName, " parameter: ").concat(key, " is missing or invalid");
            throwError(msg);
        }
    });
};
var validateOptions = function (request) {
    var msg = 'Must provide options parameter.';
    validateBody(request, msg);
};
var throwError = function (msg) {
    var error = new Error(msg);
    error.name = 'ValidationError';
    throw error;
};
