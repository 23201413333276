import { __awaiter, __generator } from "tslib";
import BROWSER from '../../../helpers/browser';
import { STATUS } from '../../../constants';
import { Logger } from '../../../logger';
import { isNullOrUndefinedOrEmpty, isError, createError, versionLessThan, parseJson, generatePromiseData } from '../../../utils';
function mimeType() {
    return 'application/x-aspera-web';
}
var NpapiStrategy = /** @class */ (function () {
    function NpapiStrategy(options) {
        var _this = this;
        this.options = options;
        this.VERSION_PREFIX = '/v5';
        this.pluginId = '';
        this.listenerId = '';
        this.name = 'npapi';
        /*
         * Create the NPAPI plugin <object> element as a child of the DOM element
         * given (if exists)
         *
         * @param {string} initializeTimeout [[AW4.Connect]] instantiation option
         */
        this.createNPAPIPlugin = function (initializeTimeout) {
            var wrapperDiv = document.getElementById(_this.listenerId);
            if (isNullOrUndefinedOrEmpty(wrapperDiv)) {
                wrapperDiv = document.createElement('div');
                wrapperDiv.setAttribute('id', _this.listenerId);
                wrapperDiv.setAttribute('style', 'display:inline-block;height:1px;width:1px;');
            }
            else {
                // Remove all elements of the wrapper
                while (wrapperDiv.firstChild) {
                    wrapperDiv.removeChild(wrapperDiv.firstChild);
                }
            }
            _this.npapiPlugin = document.createElement('object');
            _this.npapiPlugin.setAttribute('name', _this.pluginId);
            _this.npapiPlugin.setAttribute('id', _this.pluginId);
            _this.npapiPlugin.setAttribute('type', mimeType());
            _this.npapiPlugin.setAttribute('width', '1');
            _this.npapiPlugin.setAttribute('height', '1');
            var timeoutParam = document.createElement('param');
            timeoutParam.setAttribute('name', 'connect-launch-wait-timeout-ms');
            timeoutParam.setAttribute('value', String(initializeTimeout));
            _this.npapiPlugin.appendChild(timeoutParam);
            wrapperDiv.appendChild(_this.npapiPlugin);
            document.body.appendChild(wrapperDiv);
        };
        /*
         * Place a request for Connect
         *
         * @param {function} callback Function to be called when the request has finished
         * @param {int} requestId Identifier that needs to be returned when calling the given callback
        */
        this.httpRequest = function (endpoint, requestId) {
            var requestPromise = generatePromiseData();
            // NPAPI plugin doesn't accept null data even if it is a GET request
            if (isNullOrUndefinedOrEmpty(endpoint.body)) {
                endpoint.body = '';
            }
            var fullEndpoint = "".concat(_this.VERSION_PREFIX).concat(endpoint.path);
            var requestCallback = function (data) {
                /** Parse data to find out if an error ocurred */
                var parsedData = parseJson(data);
                if (isError(parsedData)) {
                    requestPromise.resolver({
                        status: parsedData.error.code,
                        body: data,
                        requestId: requestId
                    });
                }
                else {
                    requestPromise.resolver({
                        status: 200,
                        body: data,
                        requestId: requestId
                    });
                }
            };
            if (isNullOrUndefinedOrEmpty(_this.npapiPlugin)) {
                requestPromise.rejecter(new Error('Plugin not detected.'));
            }
            else {
                _this.npapiPlugin.httpRequestImplementation(endpoint.method, fullEndpoint, endpoint.body, requestCallback);
            }
            return requestPromise.promise;
        };
        /*
         * Called to initialize the plugin, it creates a new instance by appending an
         * <object> element to the DOM and runs the callback with the status
         */
        this.startup = function () { return __awaiter(_this, void 0, void 0, function () {
            var changeConnectStatus, npapiWaitPluginLoadedID_1;
            var _this = this;
            return __generator(this, function (_a) {
                changeConnectStatus = this.options.requestStatusCallback;
                try {
                    if (isNullOrUndefinedOrEmpty(this.npapiPlugin)) {
                        if ((BROWSER.IE && (new ActiveXObject('Aspera.AsperaWebCtrl.1'))) ||
                            mimeType() in navigator.mimeTypes) {
                            this.listenerId = this.options.containerId;
                            this.pluginId = this.options.id;
                            this.createNPAPIPlugin(this.options.connectLaunchWaitTimeoutMs);
                            npapiWaitPluginLoadedID_1 = setInterval(function () {
                                if (!_this.npapiPlugin || !_this.npapiPlugin.queryBuildVersion) {
                                    return;
                                }
                                clearInterval(npapiWaitPluginLoadedID_1);
                                // Check version is correct
                                if (versionLessThan(_this.npapiPlugin.queryBuildVersion(), '3.6')) {
                                    // Plugin too old
                                    _this.npapiPlugin = undefined;
                                    changeConnectStatus(STATUS.FAILED);
                                    return createError(-1, 'Plugin too old. Version less than 3.6');
                                }
                            }, 500);
                        }
                        else {
                            // If plugin is still null, it means it is not installed
                            if (isNullOrUndefinedOrEmpty(this.npapiPlugin)) {
                                changeConnectStatus(STATUS.FAILED);
                                return [2 /*return*/, createError(-1, 'Plugin not detected. Either not installed or enabled.')];
                            }
                        }
                    }
                }
                catch (error) {
                    // IE 10 ActiveXObject instantiation error recovery
                    changeConnectStatus(STATUS.FAILED);
                    Logger.debug(JSON.stringify(error));
                    return [2 /*return*/, createError(-1, "Plugin load error. Make sure plugin is enabled. Details: ".concat(error))];
                }
                return [2 /*return*/];
            });
        }); };
    }
    return NpapiStrategy;
}());
export default NpapiStrategy;
