import { __awaiter, __generator } from "tslib";
import BROWSER from '../../../helpers/browser';
import { Logger } from '../../../logger';
import * as Utils from '../../../utils';
import { DEFAULT_PORT, LOCALHOST, MAX_PORT_SEARCH, MAX_POLLING_ERRORS, STATUS } from '../../../constants';
var HttpStrategy = /** @class */ (function () {
    function HttpStrategy(options) {
        var _this = this;
        this.options = options;
        // private initCallback: any;
        this.connectStatus = STATUS.INITIALIZING;
        /** Internal tracker for port Connect is listening on */
        this.connectPort = DEFAULT_PORT;
        /** Timeout used when iterating ports */
        this.scanRetryTimeValues = [0, 1];
        /** Debugging variable to keep track of multiple instances */
        this.objectId = 0;
        /** Simple counter to increment request ids */
        this.nextId = 0;
        /** Track number of polling errors for debounce */
        this.pollingRequestErrors = 0;
        this.windowUnloading = false;
        this.VERSION_PREFIX = '/v5';
        this.name = 'http';
        /** Track http implementation state */
        this.changeConnectStatus = function (newConnectStatus) {
            if (_this.connectStatus === newConnectStatus) {
                return;
            }
            Logger.debug('[' + _this.objectId + '] Http request handler status changing from[' + _this.connectStatus
                + '] to[' + newConnectStatus + ']');
            _this.connectStatus = newConnectStatus;
            if (_this.requestStatusCallback) {
                _this.requestStatusCallback(newConnectStatus);
            }
        };
        /**
         * Iterates through ports and returns true if Connect responds
         */
        this.check = function () { return __awaiter(_this, void 0, void 0, function () {
            var pingRequests, portMap, port, requestId;
            var _this = this;
            return __generator(this, function (_a) {
                pingRequests = [];
                portMap = new Map();
                for (port = DEFAULT_PORT; port < (DEFAULT_PORT + MAX_PORT_SEARCH); port++) {
                    requestId = this.nextId++;
                    portMap.set(requestId, port);
                    this.connectPort = port;
                    pingRequests.push(this.ping(requestId));
                }
                return [2 /*return*/, Promise.all(pingRequests)
                        .then(function (pingResponses) {
                        for (var it = 0; it < pingResponses.length; it++) {
                            var _a = pingResponses[it], requestId = _a.requestId, status_1 = _a.status;
                            if (Utils.isSuccessCode(status_1)) {
                                // Retrieve port number via request id
                                var found = portMap.get(requestId);
                                if (found) {
                                    _this.connectPort = found;
                                    return true;
                                }
                            }
                        }
                        return false;
                    })];
            });
        }); };
        this.detectConnect = function (firstRun) { return __awaiter(_this, void 0, void 0, function () {
            var success, retryTimeS_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.connectStatus === STATUS.RUNNING || this.connectStatus === STATUS.STOPPED) {
                            return [2 /*return*/, true];
                        }
                        else if (this.connectStatus === STATUS.INITIALIZING && !firstRun) {
                            Utils.launchConnect();
                            this.changeConnectStatus(STATUS.RETRYING);
                        }
                        return [4 /*yield*/, this.check()];
                    case 1:
                        success = _a.sent();
                        if (!!success) return [3 /*break*/, 3];
                        retryTimeS_1 = this.scanRetryTimeValues[0] + this.scanRetryTimeValues[1];
                        this.scanRetryTimeValues[0] = this.scanRetryTimeValues[1];
                        this.scanRetryTimeValues[1] = retryTimeS_1;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.detectConnect(false)];
                                            case 1:
                                                _a.sent();
                                                /** Go to running here if Connect was installed during loop after initial timeout */
                                                this.changeConnectStatus(STATUS.RUNNING);
                                                resolve();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, retryTimeS_1 * 1000);
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, true];
                }
            });
        }); };
        this.ping = function (requestId) { return __awaiter(_this, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                request = {
                    method: 'GET',
                    path: '/connect/info/ping'
                };
                return [2 /*return*/, this.httpRequest(request, requestId)];
            });
        }); };
        this.reconnect = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.changeConnectStatus(STATUS.RETRYING);
                        Utils.launchConnect();
                        return [4 /*yield*/, this.detectConnect(false)];
                    case 1:
                        _a.sent();
                        Logger.debug('Reconnect successful!');
                        this.changeConnectStatus(STATUS.RUNNING);
                        return [2 /*return*/];
                }
            });
        }); };
        this.send = function (endpoint, requestId) {
            var requestPromise = Utils.generatePromiseData();
            var xhr = Utils.getXMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (_this.connectStatus === STATUS.STOPPED || _this.windowUnloading) {
                    Logger.debug('Connect stopped or page unloading. Skipping xhr processing.');
                    return requestPromise.rejecter(Utils.createError(-1, 'Connect stopped or page unloading. Skipping xhr processing.'));
                }
                if (xhr.readyState !== 4) {
                    return;
                }
                if (xhr.status === 0 && _this.connectStatus === STATUS.RUNNING) {
                    /**
                     * Avoid excessive relaunch related to polling failures.
                     * Safari causes CORS failures when new page navigation starts
                     */
                    if (_this.pollingRequestErrors < MAX_POLLING_ERRORS && endpoint.path.indexOf('activity') > 0) {
                        _this.pollingRequestErrors++;
                        return;
                    }
                    /** If Connect is running, don't need to iterate over ports */
                    if (endpoint.path.indexOf(_this.connectPort.toString()) === -1 && endpoint.path.indexOf('ping') > 0) {
                        return;
                    }
                    void _this.reconnect();
                }
                var response = xhr.responseText;
                Logger.trace('HttpRequest processed[' + endpoint.path + '] postData[' + endpoint.body +
                    '] status[' + xhr.status + '] response[' + response + ']');
                requestPromise.resolver({
                    status: xhr.status,
                    body: response,
                    requestId: requestId
                });
            };
            xhr.open(endpoint.method, endpoint.path, true);
            if (endpoint.method.toUpperCase() === 'GET') {
                xhr.send();
            }
            else {
                xhr.send(endpoint.body);
            }
            return requestPromise.promise;
        };
        this.httpRequest = function (endpoint, requestId) { return __awaiter(_this, void 0, void 0, function () {
            var fullpath, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullpath = "".concat(LOCALHOST).concat(this.connectPort).concat(this.VERSION_PREFIX).concat(endpoint.path);
                        // TODO: Make copy of original request
                        endpoint.path = fullpath;
                        return [4 /*yield*/, this.send(endpoint, requestId)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        }); };
        this.stop = function () {
            _this.changeConnectStatus(STATUS.STOPPED);
        };
        this.startup = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.requestStatusCallback = this.options.requestStatusCallback;
                        /** Await Connect detection */
                        return [4 /*yield*/, this.detectConnect(true)];
                    case 1:
                        /** Await Connect detection */
                        _a.sent();
                        Logger.debug('Finished http init');
                        return [2 /*return*/];
                }
            });
        }); };
        // 2018-06-30 Only Safari 12 has trouble with failing XHR requests when page navigation begins
        if (BROWSER.SAFARI_NO_NPAPI) {
            window.addEventListener('beforeunload', function () {
                _this.windowUnloading = true;
            });
        }
        // Associate request ids with object ids
        if (options.objectId) {
            this.objectId = options.objectId;
            this.nextId = this.objectId * 10000;
        }
    }
    return HttpStrategy;
}());
export default HttpStrategy;
