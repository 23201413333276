import { apiEndpoints } from '../constants';
var ApiService = /** @class */ (function () {
    function ApiService(requestHandler) {
        var _this = this;
        this.requestHandler = requestHandler;
        this.send = function (request) {
            var fullEndpoint = _this.getEndpointUrl(request.name, request.param);
            return _this.httpRequest(request.method, fullEndpoint, request.method === 'POST' ? JSON.stringify(request.body) : undefined);
        };
        this.httpRequest = function (method, path, data) {
            var endpoint = {
                method: method,
                path: path,
                body: data
            };
            return _this.requestHandler.start(endpoint);
        };
    }
    /**
     * Forms the URL to use for the API call
     */
    ApiService.prototype.getEndpointUrl = function (name, param) {
        var endpointInfo = apiEndpoints[name];
        if (!endpointInfo) {
            throw new Error("Connect API (".concat(name, ") not known"));
        }
        var route = endpointInfo.route;
        var prefix = endpointInfo.prefix;
        if (param) {
            route = route.replace('${id}', param);
        }
        return "".concat(prefix).concat(route);
    };
    return ApiService;
}());
export default ApiService;
