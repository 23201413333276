import { __awaiter, __extends, __generator, __read, __spreadArray } from "tslib";
import { Logger } from '../../../logger';
import { atou, recordConnectDetected } from '../../../utils';
import { STATUS } from '../../../constants';
import BaseExtensionStrategy from './base-ext';
var NativeHostStrategy = /** @class */ (function (_super) {
    __extends(NativeHostStrategy, _super);
    function NativeHostStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._extensionDetected = false;
        _this.name = 'nmh';
        /**
         * Handles disconnect messages from the extension.
         */
        _this.handleDisconnect = function (evt) { return __awaiter(_this, void 0, void 0, function () {
            var installIssueDetected_1, connectFound;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Logger.debug('Native host disconnected. Detail: ' + evt.detail);
                        /** Disconnect is expected if Connect is outdated */
                        if (this.connectStatus === STATUS.OUTDATED) {
                            return [2 /*return*/];
                        }
                        if (evt && evt.detail) {
                            installIssueDetected_1 = false;
                            [
                                'native messaging host not found',
                                'Error when communicating with the native messaging host',
                                'Access to the specified native messaging host is forbidden',
                                'No such native application' // Firefox
                            ].forEach(function (message) {
                                if (evt.detail.indexOf(message) !== -1) {
                                    installIssueDetected_1 = true;
                                }
                            });
                            if (installIssueDetected_1) {
                                this.changeConnectStatus(STATUS.FAILED);
                                document.removeEventListener('AsperaConnectDisconnect', this.handleDisconnect);
                                return [2 /*return*/];
                            }
                        }
                        if (!(this.connectStatus !== STATUS.DEGRADED)) return [3 /*break*/, 2];
                        this.changeConnectStatus(STATUS.DEGRADED);
                        return [4 /*yield*/, this.detectConnect(this.options.connectLaunchWaitTimeoutMs)];
                    case 1:
                        connectFound = _a.sent();
                        if (connectFound) {
                            this.changeConnectStatus(STATUS.RUNNING);
                        }
                        else {
                            this.changeConnectStatus(STATUS.FAILED);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        /**
         * Resolves the extension response
         */
        _this.resolveExtensionResponse = function (evt) {
            var data;
            if (evt.type === 'message' &&
                typeof evt.data === 'object' &&
                'type' in evt.data &&
                evt.data.type === 'AsperaConnectResponse' &&
                'detail' in evt.data) {
                data = evt.data.detail;
            }
            else if ('detail' in evt) {
                /**
                 * CustomEvent interface used in disconnect event
                 */
                // @ts-ignore
                data = evt.detail;
            }
            if (data) {
                Logger.trace('Native host impl received response: ' + JSON.stringify(data));
                var id = data.request_id;
                /**
                 * Each instance of this class will receive document events, but
                 * the request might not have originated from this instance.
                 */
                if (!(id in _this.outstandingRequests)) {
                    return;
                }
                var resolve = _this.outstandingRequests[id].resolve;
                if ('body64' in data) {
                    _this.outstandingRequests[id].response += data.body64;
                    if (data.complete === true) {
                        var resp = atou(_this.outstandingRequests[id].response);
                        delete _this.outstandingRequests[id];
                        resolve({
                            status: data.status,
                            body: resp,
                            requestId: id
                        });
                    }
                }
                else {
                    delete _this.outstandingRequests[id];
                    resolve({
                        status: data.status,
                        body: data.body,
                        requestId: id
                    });
                }
            }
        };
        _this.detectionLoop = function (timeoutMs, loop) {
            if (timeoutMs === void 0) { timeoutMs = -1; }
            return __awaiter(_this, void 0, void 0, function () {
                var timeoutPromise, found;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            timeoutPromise = new Promise(function (resolve) {
                                setTimeout(resolve, timeoutMs, false);
                            });
                            return [4 /*yield*/, Promise.race(__spreadArray(__spreadArray([], __read(timeoutMs !== -1 ? [timeoutPromise] : []), false), [
                                    loop()
                                ], false))];
                        case 1:
                            found = _a.sent();
                            clearInterval(this._detectionRetry);
                            return [2 /*return*/, found];
                    }
                });
            });
        };
        /**
         * Returns promise that resolves with true | false if Connect is detected or not.
         */
        _this.detectConnect = function (timeoutMs) {
            if (timeoutMs === void 0) { timeoutMs = -1; }
            return __awaiter(_this, void 0, void 0, function () {
                var waitUntilDetected;
                var _this = this;
                return __generator(this, function (_a) {
                    waitUntilDetected = function () {
                        return new Promise(function (resolve) {
                            var attemptNumber = 1;
                            var check = function () { return __awaiter(_this, void 0, void 0, function () {
                                var endpoint, detectConnectRequestId, status_1, error_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            Logger.debug('Detecting Connect installation via extension. Attempt ' + attemptNumber);
                                            attemptNumber++;
                                            endpoint = {
                                                method: 'GET',
                                                path: '/connect/info/version'
                                            };
                                            detectConnectRequestId = this.options.objectId * 10500;
                                            return [4 /*yield*/, this.httpRequest(endpoint, detectConnectRequestId)];
                                        case 1:
                                            status_1 = (_a.sent()).status;
                                            if (status_1 === 503) {
                                                Logger.debug('Detected old version of Connect via extension.');
                                                this.changeConnectStatus(STATUS.OUTDATED);
                                            }
                                            else {
                                                Logger.debug('Detected Connect installation via extension.');
                                                recordConnectDetected();
                                                /** Go to running here if Connect was installed during loop after initial timeout */
                                                this.changeConnectStatus(STATUS.RUNNING);
                                                clearInterval(this._detectionRetry);
                                                resolve(true);
                                            }
                                            return [3 /*break*/, 3];
                                        case 2:
                                            error_1 = _a.sent();
                                            /** If there was an error, avoid infinitely retrying */
                                            clearInterval(this._detectionRetry);
                                            resolve(false);
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); };
                            _this._detectionRetry = setInterval(check, 1000);
                            /** Call check() directly to avoid waiting the initial 1 second */
                            void check();
                        });
                    };
                    return [2 /*return*/, this.detectionLoop(timeoutMs, waitUntilDetected)];
                });
            });
        };
        /**
         * Returns promise that resolves with true | false if extension is detected or not.
         */
        _this.detectExtension = function (timeoutMs) {
            if (timeoutMs === void 0) { timeoutMs = -1; }
            return __awaiter(_this, void 0, void 0, function () {
                var waitUntilDetected;
                var _this = this;
                return __generator(this, function (_a) {
                    if (this._extensionDetected) {
                        return [2 /*return*/, true];
                    }
                    waitUntilDetected = function () {
                        return new Promise(function (resolve) {
                            var attemptNumber = 1;
                            var check = function () {
                                Logger.debug('Detecting Connect extension. Attempt ' + attemptNumber);
                                attemptNumber++;
                                document.dispatchEvent(new CustomEvent('AsperaConnectCheck', {}));
                            };
                            var versionResponse = function (evt) {
                                if (evt.type === 'message' && typeof evt.data === 'object' && 'type' in evt.data
                                    && evt.data.type === 'AsperaConnectCheckResponse') {
                                    window.removeEventListener('message', versionResponse);
                                    Logger.debug('Extension detected: ' + JSON.stringify(evt.data));
                                    _this._extensionDetected = true;
                                    clearInterval(_this._detectionRetry);
                                    resolve(true);
                                }
                            };
                            window.addEventListener('message', versionResponse);
                            var interval = timeoutMs === -1 ? 1000 : 200;
                            _this._detectionRetry = setInterval(check, interval);
                            void check();
                        });
                    };
                    return [2 /*return*/, this.detectionLoop(timeoutMs, waitUntilDetected)];
                });
            });
        };
        _this.stop = function () {
            clearInterval(_this._detectionRetry);
        };
        /**
         * Returns only once extension and Connect are both detected. Caller handles
         * any timeout.
         */
        _this.startup = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        /** Setup extension response handlers */
                        // @ts-ignore
                        document.addEventListener('AsperaConnectResponse', this.resolveExtensionResponse);
                        window.addEventListener('message', this.resolveExtensionResponse);
                        /** Register disconnect handler before init to handle native host not found issues during install */
                        document.addEventListener('AsperaConnectDisconnect', this.handleDisconnect);
                        /** Await extension detection */
                        return [4 /*yield*/, this.detectExtension()];
                    case 1:
                        /** Await extension detection */
                        _a.sent();
                        /** Await Connect detection */
                        return [4 /*yield*/, this.detectConnect()];
                    case 2:
                        /** Await Connect detection */
                        _a.sent();
                        Logger.debug('nmh init finished');
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    return NativeHostStrategy;
}(BaseExtensionStrategy));
export default NativeHostStrategy;
