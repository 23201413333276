import { __read, __spreadArray } from "tslib";
import { LS_LOG_KEY } from './constants';
var LEVEL = {
    INFO: 0,
    DEBUG: 1,
    TRACE: 2
};
var LogLevel = LEVEL.INFO;
if (typeof localStorage !== 'undefined' && Object.prototype.hasOwnProperty.call(localStorage, LS_LOG_KEY)) {
    LogLevel = Number(localStorage.getItem(LS_LOG_KEY));
}
function trace() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (LogLevel >= LEVEL.TRACE) {
        print('log', args);
    }
}
function debug() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (LogLevel >= LEVEL.DEBUG) {
        print('log', args);
    }
}
/*
 * AW4.Logger.log(message) -> No return value
 * -message (String): A check for if window.console is defined is performed,
 * and if window.console is defined, then message will be sent to
 * console.log.
 */
function log() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    print('log', args);
}
/*
 * AW4.Logger.warn(message) -> No return value
 * -message (String): A check for if window.console is defined is performed,
 * and if window.console is defined, then message will be sent to
 * console.warn.
 */
function warn() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    print('warn', args);
}
/*
 * AW4.Logger.error(message) -> No return value
 * -message (String): A check for if window.console is defined is performed,
 * and if window.console is defined, then message will be sent to
 * console.error.
 */
function error() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    print('error', args);
}
function print(level, message) {
    var _a;
    if (typeof window.console !== 'undefined') {
        (_a = window.console)[level].apply(_a, __spreadArray([], __read(message), false));
    }
}
/*
 * Sets the logging level for the Connect SDK.
 *
 * Levels:
 * * `0` - INFO
 * * `1` - DEBUG
 * * `2` - TRACE
 */
function setLevel(level) {
    LogLevel = level;
    localStorage[LS_LOG_KEY] = level;
}
export var Logger = { debug: debug, error: error, log: log, setLevel: setLevel, trace: trace, warn: warn };
