import { __awaiter, __generator } from "tslib";
import BROWSER from '../../helpers/browser';
import { STATUS } from '../../constants';
import * as Utils from '../../utils';
import { Logger } from '../../logger';
import { ConnectInstaller } from '../../installer';
import NpapiStrategy from './npapi';
import { NativeHostStrategy, SafariAppStrategy } from './extension';
import HttpStrategy from './http';
/*
 * Responsible for selecting the appropriate request implementation to
 * use for handling API requests.
 */
var Provider = /** @class */ (function () {
    function Provider(_options) {
        var _this = this;
        this._options = _options;
        this.create = function (klass) {
            return new klass(_this._options);
        };
        this.getHttpStrategy = function () {
            return _this.create(HttpStrategy);
        };
        this.getStrategy = function () { return __awaiter(_this, void 0, void 0, function () {
            var installed, supportsInstall, e_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, , 6]);
                        if (!this.supportsNpapi()) return [3 /*break*/, 1];
                        Logger.debug('Using npapi strategy');
                        this.strategy = this.create(NpapiStrategy);
                        return [3 /*break*/, 4];
                    case 1:
                        if (!this.requiresHttp()) return [3 /*break*/, 2];
                        this.setHttpStrategy();
                        return [3 /*break*/, 4];
                    case 2:
                        Logger.debug('Attempting extension strategy');
                        this.strategy = this.supportsNativeHost() ? this.create(NativeHostStrategy) : this.create(SafariAppStrategy);
                        Logger.debug('Checking if extension installed...');
                        return [4 /*yield*/, ((_b = (_a = this.strategy).detectExtension) === null || _b === void 0 ? void 0 : _b.call(_a, 1000))];
                    case 3:
                        installed = _c.sent();
                        if (!installed) {
                            supportsInstall = ConnectInstaller.supportsInstallingExtensions === true;
                            if (this._options.connectMethod === 'extension' || supportsInstall) {
                                if (!this.supportsSafariAppExt()) {
                                    this._options.requestStatusCallback(STATUS.EXTENSION_INSTALL);
                                    window.postMessage('show_extension_install', '*');
                                }
                            }
                            else {
                                if (this.supportsHttp()) {
                                    Logger.debug('Falling back to http strategy');
                                    this.setHttpStrategy();
                                }
                            }
                        }
                        _c.label = 4;
                    case 4: return [2 /*return*/, this.strategy];
                    case 5:
                        e_1 = _c.sent();
                        throw new Error("Unexpected error while determining the request implementation: ".concat(e_1));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.requiresHttp = function () {
            return (_this._options.connectMethod === 'http' || !_this.supportsExtensions() || Utils.checkVersionException());
        };
        this.setHttpStrategy = function () {
            Logger.debug('Using http strategy');
            _this.strategy = _this.getHttpStrategy();
        };
        this.supportsExtensions = function () {
            return (_this.supportsNativeHost() || _this.supportsSafariAppExt());
        };
        this.supportsHttp = function () {
            return !BROWSER.SAFARI;
        };
        this.supportsNativeHost = function () {
            return (BROWSER.CHROME || BROWSER.EDGE_WITH_EXTENSION || BROWSER.FIREFOX);
        };
        this.supportsNpapi = function () {
            return (BROWSER.IE || BROWSER.SAFARI && !BROWSER.SAFARI_NO_NPAPI);
        };
        this.supportsSafariAppExt = function () {
            return BROWSER.SAFARI_NO_NPAPI;
        };
    }
    return Provider;
}());
export default Provider;
